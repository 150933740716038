<template>
  <div class="container">
    <div id="map-container"></div>
    <div class="info">
      <table>
        <tr v-for="item in tableColumns">
          <td>{{ item.title }}</td>
          <td>{{ userInfo[item.dataIndex] }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import config from '../config/bmap.json'
import request from '../config/require'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'Bmap',
  data() {
    return {
      tableColumns: [],
      start: {
        lng: null,
        lat: null,
      },
      startOverlay: null,
      end: {
        lng: null,
        lat: null,
      },
      endOverlay: null,
      car: {
        lng: null,
        lat: null,
      },
      carOverlay: null,
      map: null,
      completeRouteResult: [],
      completeOverlay: null,
      incompleteRouteResult: [],
      incompleteOverlay: null,
      sw: null,
    }
  },
  beforeMount() {
    this.tableColumns = config.tableColumns
    this.start = config.start
    this.end = config.end
  },
  computed: {
    ...mapState(['userInfo']),
    ...mapGetters(['rkId']),
    startPoint() {
      return new BMapGL.Point(this.start.lng, this.start.lat)
    },
    endPoint() {
      return new BMapGL.Point(this.end.lng, this.end.lat)
    },
    isStart() {
      return this.car.lng === this.start.lng && this.car.lat === this.start.lat
    },
    isEnd() {
      return this.car.lng === this.end.lng && this.car.lat === this.end.lat
    },
  },
  watch: {
    car() {
      this.convertor([new BMapGL.Point(this.car.lng, this.car.lat)], (data) => {
        const carPoint = data[0]

        if(this.incompleteRouteResult.length === 0) {
          this.planning(carPoint, this.endPoint, (routeResult) => {
            this.incompleteRouteResult = routeResult
          })
        }

        if (this.carOverlay) {
          this.map.removeOverlay(this.carOverlay)
        }

        this.carOverlay = this.renderPoint(carPoint, config.img.car)

        this.map.panTo(carPoint)
      })
    },
    completeRouteResult() {
      if (this.completeOverlay) {
        this.map.removeOverlay(this.completeOverlay)
      }
      if (this.completeRouteResult.length > 0) {
        this.car = this.completeRouteResult[this.completeRouteResult.length - 1]

        const points = this.completeRouteResult.map((item) => {
          return new BMapGL.Point(item.lng, item.lat)
        })

        this.convertor(points, (data) => {
          this.completeOverlay = this.renderLine(
            data,
            config.line.complete
          )
        })
      }
    },
    incompleteRouteResult() {
      if (this.incompleteOverlay) {
        this.map.removeOverlay(this.incompleteOverlay)
      }
      const points = this.incompleteRouteResult.map((item) => {
        return new BMapGL.Point(item.lng, item.lat)
      })
      this.incompleteOverlay = this.renderLine(points, config.line.incomplete)
    },
    rkId() {
      if (this.sw) {
        clearInterval(this.sw)
      }
      if (this.userInfo.ambulanceCode) {
        this.search()
        this.sw = setInterval(() => {
          this.search()
        }, config.searchTime * 1000)
      }
    },
  },
  mounted() {
    this.initAMap()
    this.convertor([this.endPoint], (data) => {
      this.endOverlay = this.renderPoint(data[0], config.img.end)
    })
  },
  unmounted() {
    this.map?.destroy()
  },
  methods: {
    initAMap() {
      this.map = new BMapGL.Map('map-container')
      this.map.centerAndZoom(this.startPoint, 15)
      this.map.enableScrollWheelZoom()
      this.map.setTilt(60)
    },
    // 渲染点
    renderPoint(point, img) {
      const marker = new BMapGL.Marker(point, {
        icon: new BMapGL.Icon(
          require(`/src/assets/img/${img.url}`),
          new BMapGL.Size(img.width, img.height)
        ),
      })
      this.map.addOverlay(marker)
      return marker
    },
    // 渲染线
    renderLine(points, line) {
      const polyline = new BMapGL.Polyline(points, {
        strokeStyle: line.style,
        strokeWeight: line.weight,
        strokeColor: line.color,
      })
      this.map.addOverlay(polyline);
      return polyline
    },
    //规划路线
    planning(startPoint, endPoint, callback) {
      const driving = new BMapGL.DrivingRoute(startPoint, {
        onSearchComplete: (result) => {
          if (result.getPlan(0) && result.getPlan(0).getRoute(0)) {
            const routeResult = result.getPlan(0).getRoute(0).getPath()
            callback(routeResult)
          }
        }
      })
      driving.search(startPoint, endPoint)
    },
    /**
     * 转换点
     *  注意：百度限制一次批量最多只能转换10个点，分批转换后统一回调
     * 
     * status 说明
     *  0	  ok 正常 服务请求正常召回
     *  1	  内部错误
     *  4	  转换失败 X→GPS 时必现，根据法律规定，不支持将任何类型的坐标转换为 GPS 坐标
     *  21	from 非法
     *  22	to 非法
     *  24	coords 格式非法
     *  25	coords 个数非法，超过限制
     *  26	参数错误
     */
    convertor(points, callback) {
      const batchSize = 10
      const allConvertedPoints = []

      const convertor = new BMapGL.Convertor()
      
      for (let i = 0; i < points.length; i += batchSize) {  
        const batch = points.slice(i, i + batchSize);  
        convertor.translate(batch, COORDINATES_WGS84, COORDINATES_BD09, (data) => {
          if (data.status === 0) {  
            allConvertedPoints.push(...data.points)
          }  
            
          if (allConvertedPoints.length === points.length) {
            callback(allConvertedPoints)
          }
        })
      }
    },
    search() {
      request
        .get(`getNewGPSList/${this.userInfo.ambulanceCode}`, {
          time: this.userInfo.zyDateTime,
        })
        .then((res) => {
          if (res && res?.data?.code === '00000') {
            if (res.data.data) {
              this.completeRouteResult = res.data.data.map((item) => {
                return { lng: item.longitude, lat: item.latitude }
              })
            } else {
              console.error('没有数据')
            }
          }
        })
    }
  }
}
</script>
<style scoped lang="scss">
.container {
  padding: 6.8px;
  width: 100%;
  height: 100%;
  position: relative;

  #map-container {
    width: 100%;
    height: 48.1vh;
    border-radius: 7px;
  }

  .info {
    position: absolute;
    top: 10px;
    left: 10px;
    background: #02031c;
    color: #fff;
    // border-radius: 7px;
    z-index: 99;

    table {
      border-collapse: collapse;
      width: 100%;

      tr,
      td {
        border: 1px solid #97e4fa;
        padding: 8px;
        text-align: center;
        font-size: 10px;
      }

      td:nth-child(1) {
        width: 70px;
      }

      td:nth-child(2) {
        width: 150px;
      }
    }
  }

  ::v-deep {
    .BMap_cpyCtrl {
      display: none;
    }

    .anchorBL {
      display: none;
    }
  }
}
</style>
