<template>
  <div class="box" id="box">
    <!-- <div class="time">转院时间：{{ $store.state.userInfo.zyDateTime }}</div> -->
    <div class="title">生 命 体 征</div>
    <!-- <video src="../../public/video/IYID3249.MOV" /> -->
    <div class="content" id="right-view-body">
      <div id="table">
        <table>
          <tr v-for="(item, index) in table?.data" :key="index">
            <td v-for="(subItem, index) in item" :key="index">{{ subItem }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="jinggao" v-show="isVisible">
      <img :src="imagePath" />
    </div>
    <canvas id="my-canvas-body" ref="bodyCanvas"></canvas>
  </div>
</template>

<script>
//import * as THREE from "three";
import * as THREE from '@three/three.module.js'
import { GLTFLoader } from '@jsm/loaders/GLTFLoader.js'
import { OrbitControls } from '@jsm/controls/OrbitControls.js'
import { EffectComposer } from '@jsm/postprocessing/EffectComposer.js'
import { RenderPass } from '@jsm/postprocessing/RenderPass.js'
import { UnrealBloomPass } from '@jsm/postprocessing/UnrealBloomPass.js'
import { bodyClient } from '../config/config'
// import { GUI } from '@jsm/libs/dat.gui.module.js';
import Stats from '@jsm/libs/stats.module.js'
var WIDTH = window.innerWidth
var HEIGHT = window.innerHeight
const bg = require('../assets/img/3d/3dperson/bg5.jpeg')
const l2r = require('../assets/img/3d/3dperson/left2right.png')
const down2upJpg = require('../assets/img/3d/3dperson/down2up.png')
// const jinggao = require('../assets/img/jinggao.png')
// const jinggao = require('../assets/img/1.gif')
const jinggao = require('../assets/img/jg1.gif')
const prompt = require('../assets/audio/xin.wav')
let boxMesh = null
let thismodel = null
let modelmaterial = null
let scene = null

import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
  components: '',
  data() {
    return {
      imagePath: jinggao, // 图片路径
      isVisible: false,
      timer: null,
      loader: new GLTFLoader(),
      mains: [],
      stats: {},
      renderer: {},
      scene: {},
      camera: {},
      orbitcontrols: {},
      composer: {},
      gui: {},
      bloomPass: {},
      clock: new THREE.Clock(),
      manshow: true, // 控制显示的人物是男或者女,
      showYype: 'down2up', // 控制人物显示的方向
      personTexture: new THREE.TextureLoader(),
      API: {
        strength: 1.6,
        radius: 0,
        threshold: 0.08,
        speed: 0.02,
        lineColor: 0x1d69d8,
      },
      pixelRation: {
        ratio: 0,
        devicePixelRatio: 0,
        backingStoreRatio: 0,
      },
      container: {
        width: 0,
        height: 0,
      },
      table: {
        row: 2,
        cloumn: 3,
        data: [
          ['姓名', ''],
          ['性别', ''],
          ['年龄', ''],
        ],
      },
    }
  },
  created() {
    this.$nextTick(() => {
      this.initStats()
      this.initRenderer()
      this.initScene()
      this.initLights()
      this.initCamera()
      this.initRenderPass()
      this.init3DPerson()
      this.initControls()
      this.initHelp()
      this.initGui()
      this.initMyCanvas(this.container)
      window.addEventListener('resize', this.onWindowResize, false)
    })
  },
  computed: {
    ...mapGetters(['peopleInfo', 'peopleState', { userID: 'rkId' }]),
    ...mapState(['vitalSignsList', 'userState']),
  },
  watch: {
    peopleState: {
      handler(newVal, oldVal) {
        this.initMyCanvas()
      },
      immediate: false,
      deep: true,
    },
    peopleInfo: {
      handler(newVal, oldVal) {
        // users
        let map = this.table.data.map((item, index, arr) => {
          item[1] = newVal[index]
          return item
        })
        console.log('患者列表', map)
        map.forEach((item, index) => {
          this.$set(this.table.data, index, item)
        })
        //  newVal.forEach((item, index, arr) => {
        //   this.$set()
        //  })
      },
      immediate: false,
      deep: true,
    },
    userState: {
      handler() {
        if (this.userState === 'start') {
          // start  over
          this.showImage()
        }
      },
    },
  },
  mounted() {},
  methods: {
    showImage() {
      this.isVisible = true
      this.timer = setTimeout(() => {
        this.isVisible = false
      }, 3000) // 4秒后执行
    },

    getPixelRation(context) {
      var devicePixelRatio = window.devicePixelRatio || 1
      var backingStoreRatio =
        context.webkitBackingStorePixelRatio ||
        context.mozBackingStorePixelRatio ||
        context.msBackingStorePixelRatio ||
        context.oBackingStorePixelRatio ||
        context.backingStorePixelRatio ||
        1
      return {
        ratio: devicePixelRatio / backingStoreRatio,
        devicePixelRatio: devicePixelRatio,
        backingStoreRatio: backingStoreRatio,
      }
    },

    // 用canvas 书写一个helloworld 字，并且文字颜色是白色，文字大小是12px
    initMyCanvas({ width = 600 + 100, height = 600 } = this.container) {
      this.position = bodyClient
      var myCanvas = document.getElementById('my-canvas-body')
      let ctx = myCanvas.getContext('2d')
      this.pixelRation = this.getPixelRation(ctx)
      let { ratio } = this.pixelRation
      myCanvas.width = width * ratio
      myCanvas.height = height * ratio
      myCanvas.style.width = width + 'px'
      myCanvas.style.height = height + 'px'
      ctx.font = '18px Consolas'
      let { line, text } = this.position
      if (
        text === null ||
        text.length < 1 ||
        line === null ||
        line.length < 1
      ) {
        return
      }

      // draw text;
      console.log('打印1111111', this.peopleState)
      text.forEach((item, index) => {
        ctx.fillStyle = item.color
        ctx.textBaseLine = 'alphabetic'
        ctx.font = '18px Consolas'
        ctx.fillText(
          item.text + this.peopleState[index] + item.unit,
          item.x * width * ratio,
          item.y * height * ratio
        )
      })

      line.forEach((item) => {
        ctx.beginPath()
        ctx.moveTo(item.x1 * width * ratio, item.y1 * height * ratio)
        ctx.lineTo(item.x2 * width * ratio, item.y2 * height * ratio)
        ctx.lineTo(item.x3 * width * ratio, item.y3 * height * ratio)
        ctx.strokeStyle = item.color
        ctx.lineWidth = 2
        // ctx.closePath();
        ctx.stroke()
      })

      // draw tabale
    },
    initStats() {
      this.stats = new Stats()
    },

    initRenderer() {
      var container = document.getElementById('right-view-body')
      this.container.width = container.offsetWidth
      this.container.height = container.offsetHeight
      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
        logarithmicDepthBuffer: true,
      })
      this.renderer.setPixelRatio(window.devicePixelRatio)
      // this.renderer.setSize(WIDTH, HEIGHT);
      this.renderer.setSize(container.offsetWidth, container.offsetHeight)

      // this.renderer.setSize("100px", "200px");
      // this.renderer.domElement.style.transform = "scaleX(1.2)"
      this.renderer.toneMapping = THREE.ReinhardToneMapping //这个不知道干嘛用
      // renderer.setClearColor(0x000000, 1)   //设置场景背景色
      container.appendChild(this.renderer.domElement)
    },

    initScene() {
      // 背景图片
      scene = new THREE.Scene()
      const texture = new THREE.TextureLoader()
      const bgtexture = texture.load(bg) //加载背景图片
      scene.background = bgtexture
    },

    initCamera() {
      this.camera = new THREE.PerspectiveCamera(45, WIDTH / HEIGHT, 1, 10000)
      this.camera.position.set(3.31, 1.65, -270) // 设置相机位置(x, y z) y控制高度,z控制当前的距离
      this.camera.lookAt(scene.position) // 设置相机方向(指向的场景对象)
    },

    initControls() {
      this.orbitcontrols = new OrbitControls(
        this.camera,
        this.renderer.domElement
      )
      this.orbitcontrols.enableDamping = true //惯性
      this.orbitcontrols.dampingFactor = 0.1 //惯性系数
      this.orbitcontrols.enablePan = false // 禁止左右平移
      this.orbitcontrols.enableZoom = false // 禁止缩放
      this.orbitcontrols.maxPolarAngle = Math.PI / 2 // 上下旋转的最小角度控制
      this.orbitcontrols.minPolarAngle = Math.PI / 2 // 上下旋转的最大角度控制
      this.orbitcontrols.addEventListener('change', this.printPosition)
    },

    printPosition() {
      console.info('X=' + this.camera.position.x)
      console.info('Y=' + this.camera.position.y)
      console.info('Z=' + this.camera.position.z)
    },

    initLights() {
      // 添加点光源
      var light = new THREE.PointLight(0xe38210, 1)
      light.position.set(0, 50, 0)
      light.name = '点光源'
      scene.add(light)
      // 环境光
      var ambient = new THREE.AmbientLight(0xffffff, 0.6)
      scene.add(ambient)
    },

    initRenderPass() {
      // 加载后期发光效果
      var renderScene = new RenderPass(scene, this.camera)
      //辉光
      this.bloomPass = new UnrealBloomPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight),
        1.6,
        0.1,
        0.1
      )
      this.bloomPass.strength = 1.6 // 发光强度
      this.bloomPass.radius = 0 // 发光半径
      this.bloomPass.threshold = 0.08 // 发光阈值
      this.renderer.toneMappingExposure = 1.2 //设置场景曝光度
      this.composer = new EffectComposer(this.renderer)
      this.composer.addPass(renderScene)
      this.composer.addPass(this.bloomPass)
    },

    animate() {
      this.stats.update()
      if (this.orbitcontrols) this.orbitcontrols.update()
      if (this.showYype == 'left2right') {
        modelmaterial.map.offset.x += 0.008
      } else {
        modelmaterial.map.offset.y += 0.003
      }
      if (scene.getObjectByName('底部小圆')) {
        scene.getObjectByName('底部小圆').rotation.y += this.API.speed
      }

      if (this.manshow) {
        //true为男子
        if (scene.getObjectByName('男'))
          scene.getObjectByName('男').visible = true
        if (scene.getObjectByName('女'))
          scene.getObjectByName('女').visible = false
      } else {
        scene.getObjectByName('女').visible = true
        scene.getObjectByName('男').visible = false
      }

      requestAnimationFrame(this.animate)
      this.renderer.clear()
      this.renderer.render(scene, this.camera)
      this.composer.render()
    },

    init3DPerson() {
      let _this = this
      // const modeltexture = this.personTexture.load(l2r); // 加载贴图
      const modeltexture = this.personTexture.load(down2upJpg) // 加载贴图
      modeltexture.wrapS = THREE.RepeatWrapping
      modeltexture.wrapT = THREE.RepeatWrapping
      modeltexture.repeat.set(1, 1)
      modelmaterial = new THREE.MeshLambertMaterial({
        color: 0x1d69d8,
        transparent: true, //允许透明计算
        opacity: 0.9, //半透明设置
        map: modeltexture,
      })

      this.loader.load(
        '/gltf/3dperson/people.glb',
        function (gltf) {
          thismodel = gltf.scene
          thismodel.name = 'gltfmodel'
          thismodel.scale.set(0.8, 0.46, 0.5)
          thismodel.traverse(function (child) {
            if (child.isMesh && (child.name == '男' || child.name == '女')) {
              boxMesh = child
              child.material = modelmaterial
              var edges = new THREE.EdgesGeometry(child.geometry, 0)
              var edgesMaterial = new THREE.LineBasicMaterial({
                color: 0x1d69d8,
              })
              var line = new THREE.LineSegments(edges, edgesMaterial)
              child.add(line)
            }
          })
          scene.add(thismodel)
          _this.animate()
        },
        undefined,
        function (e) {
          console.error(e)
        }
      )
    },
    updateUvTransform() {
      this.bloomPass.strength = this.API.strength // 发光强度
      this.bloomPass.radius = this.API.radius // 发光半径
      this.bloomPass.threshold = this.API.threshold // 发光阈值
    },
    initGui() {
      // let _this = this;
      // this.gui = new GUI();
      // this.gui.add(this.API, 'strength', 0, 20).name("发光强度").onChange(this.updateUvTransform);
      // this.gui.add(this.API, 'radius', 0, 20).name("发光半径").onChange(this.updateUvTransform);
      // this.gui.add(this.API, 'threshold', 0, 1).name("发光阈值").onChange(this.updateUvTransform);
      // this.gui.addColor(this.API, 'lineColor').name('描边颜色').onChange(function (value) {
      //   scene.getObjectByName("男").children[0].material.color = new THREE.Color(value)
      //   scene.getObjectByName("女").children[0].material.color = new THREE.Color(value)
      //   modelmaterial.color = new THREE.Color(value)
      // });
      // var dropdown = { 性别: '男' };
      // var states = ['男', '女'];
      // var clipCtrl = this.gui.add(dropdown, '性别').options(states);
      // clipCtrl.onChange(function (value) {
      //   if (value == '男') {
      //     this.manshow = true
      //   } else {
      //     this.manshow = false
      //   }
      // });
      // var dropdown = { 扫描方向: '从左到右' };
      // var states = ['从下到上', '从左到右'];
      // var clipCtrl = this.gui.add(dropdown, '扫描方向').options(states);
      // clipCtrl.onChange(function (value) {
      //   if (value == '从左到右') {
      //     _this.showYype = 'left2right'
      //     const left2right = _this.personTexture.load(l2r); // 加载贴图
      //     // left2right.offset.set(0.5, 0.5)
      //     left2right.wrapS = THREE.RepeatWrapping
      //     left2right.wrapT = THREE.RepeatWrapping
      //     left2right.repeat.set(1, 1)
      //     modelmaterial.map = left2right
      //   } else {
      //     _this.showYype = 'down2up'
      //     const down2up = _this.personTexture.load(down2upJpg); // 加载贴图
      //     // down2up.offset.set(0.5, 0.5)
      //     down2up.wrapS = THREE.RepeatWrapping
      //     down2up.wrapT = THREE.RepeatWrapping
      //     down2up.repeat.set(1, 1)
      //     modelmaterial.map = down2up
      //   }
      // });
      // this.gui.add(this.API, 'speed', -1, 1).name("底部圆盘转速").onChange();
    },

    initHelp() {
      var axes = new THREE.AxesHelper(10000)
    },

    onError() {
      alert('报错了')
    },

    onWindowResize() {
      let container = document.getElementById('right-view-body')
      this.container.width = container.offsetWidth
      this.container.height = container.offsetHeight
      this.initMyCanvas(this.container)
      this.camera.aspect = container.offsetWidth / container.offsetHeight
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setSize(container.offsetWidth, container.offsetHeight)
    },
    render() {},
  },
  beforeDestroy() {
    // 清除定时器
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  },
}
</script>

<style scoped lang="scss">
.box {
  position: relative;
  padding: 10px;

  // .time {
  //   color: #fff;
  //   font-size: 10px;
  //   position: absolute;
  //   left: 10px;
  //   top: 50px;
  // }

  .title {
    position: absolute;
    top: 4%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    font-size: 20px;
    text-align: center;
  }

  .content {
    // display: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -100;
    padding: 10px 0;
  }
  .jinggao {
    width: 60px;
    height: 70px;
    position: absolute;
    top: 12px;
    left: 30px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  #my-canvas-body {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    color: aliceblue;
  }
  #table {
    position: absolute;
    bottom: 3%;
    left: 1%;

    table {
      border-collapse: collapse;
      width: 100%;
    }
  }
  th,
  td {
    border: 1px solid #2e6099;
    padding: 8px;
    text-align: center;
    font-size: 10px;
  }
  td:nth-child(1) {
    width: 46px;
  }
  td:nth-child(2) {
    width: 60px;
  }
}
</style>
