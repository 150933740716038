import Vue from 'vue'
import App from './App.vue'
import store from './config/store'
// import {mapSecret as key} from "@/config/config" 

Vue.config.productionTip = false
// 将dataV自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
import echarts from 'echarts';
import axios from '../node_modules/axios'
Vue.prototype.$http = axios

// import BaiduMap from 'vue-baidu-map';
// Vue.use(BaiduMap, {
//   ak: 'fbTQXlsjWmypc1GsTewg19PeY9pCVDKe'
// })
// import VueBMap from 'vue-bmap-gl';
// import 'vue-bmap-gl/dist/style.css';

// Vue.use(VueBMap);

// VueBMap.initBMapApiLoader({
//   ak: key,//这个密钥请使用自己注册的
//   v: '1.0'
// });
import FullScreenContainer from 'vue-fullscreen-container'
Vue.use(FullScreenContainer)

Vue.prototype.$echarts = echarts;

Vue.use(dataV)

const urlParams = new URLSearchParams(window.location.search)
if (urlParams.get('code')) {
  store.commit('setCode', urlParams.get('code'))
}

import VueNativeSocket from 'vue-native-websocket'
Vue.use(VueNativeSocket, `wss://cpcdfp.iyicifang.com/cpcdfillin/websocket/${store.state.code}`, {
// Vue.use(VueNativeSocket, `ws://192.168.31.193:8080/cpcdfillin/websocket/${store.state.code}`, {
  store: store,
  format: 'json',
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay:    0
})

new Vue({
  axios,
  store, // store is created by vuex
  render: h => h(App),
}).$mount('#app')
