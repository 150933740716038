import axios from "axios";
import {tenantId} from './config'
// import {io} from 'socket.io-client';
console.log(document.location.host);
//const wsurl = 'ws::' + '//' + adress + 'wsocket' + '/cpcdfillin/websocket/000000';
// export const socket = new WebSocket("ws://" + document.location.host + '/wsocket/cpcdfillin/websocket/000000');
// export const socket = io(wsurl);
const instance = axios.create({
 baseURL: 'https://cpcdfp.iyicifang.com/cpcdfillin/zyApi/', 
//  baseURL: 'http://192.168.31.193:8080/cpcdfillin/zyApi/', // 本地
 timeout: 5000,
 headers: { 
    'Content-Type': 'application/json', 
    tenantId
},
});

export default {
 get(url, params) {
   return instance.get(url, { params });
 },
 post(url, data) {
   return instance.post(url, data);
 },
 put(url, data) {
   return instance.put(url, data);
 },
 delete(url) {
   return instance.delete(url);
 },
  
};
