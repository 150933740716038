<template>
  <div class="box">
    <div class="title">历 史 数 据</div>
    <div v-show="show" class="content">
      <div id="cter" style="width: 27vw; height: 35vh"></div>
    </div>
    <p v-show="!show" class="none">
      <span> 暂 无 转 院 患 者 </span>
    </p>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import requset from '../config/require'
import * as echarts from 'echarts/core'
const colors = ['#f1ca6a', '#25b5ea', '#de6e69', '#9bcb7e']
var heatterChart
export default {
  data() {
    return {
      show: true,
      option: {
        color: colors,
        grid: {
          left: '10%',
          right: '12%',
          bottom: '10%',
          containLabel: false,
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['高压', '低压', '心率', '体温'],
          textStyle: {
            color: '#fff',
          },
          top: '25vh',
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff',
            },
          },
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: '高压',
            type: 'line',
            data: [],
          },
          {
            name: '低压',
            type: 'line',
            data: [],
          },
          {
            name: '心率',
            type: 'line',
            data: [],
          },
          {
            name: '体温',
            type: 'line',
            data: [],
          },
        ],
      },
    }
  },
  computed: {
    ...mapState(['vitalSignsList', 'userState']),
    ...mapGetters(['rkId']),
  },
  watch: {
    userState: {
      handler() {
        if (this.userState === 'over') {
          this.show = false
        } else {
          this.show = true
          this.$nextTick(() => {
            heatterChart.resize()
          })
        }
      },
      immediate: true,
      deep: true,
    },
    rkId: {
      handler() {
        if (!this.rkId) {
          return
        }
        this.getData()
      },
      immediate: true,
      deep: true,
    },
    vitalSignsList: {
      handler() {
        heatterChart.setOption({
          xAxis: {
            data: this.vitalSignsList.timeList,
          },
          series: [
            {
              data: this.vitalSignsList.systolicList,
            },
            {
              data: this.vitalSignsList.diastolicList,
            },
            {
              data: this.vitalSignsList.heartRateList,
            },
            {
              data: this.vitalSignsList.temperatureList,
            },
          ],
        })
      },
      immediate: false,
      deep: true,
    },
  },
  mounted() {
    this.initChart()
  },
  methods: {
    ...mapMutations(['setVitalSignsList']),
    getData() {
      requset
        .get(`getVitalSignsStatistics/${this.$store.getters.rkId}`)
        .then((res) => {
          if (!res.data.code === '00000') {
            return
          }

          const {
            timeList,
            systolicList,
            diastolicList,
            heartRateList,
            temperatureList,
          } = res.data.data

          this.setVitalSignsList({
            timeList,
            systolicList,
            diastolicList,
            heartRateList: heartRateList.map((item) => item.value),
            temperatureList: temperatureList.map((item) => item.value),
          })
        })
    },
    initChart() {
      heatterChart = echarts.init(document.getElementById('cter'))
      heatterChart.setOption(this.option)
      window.addEventListener('resize', function () {
        heatterChart.resize();
      });
    },
  },
}
</script>
<style scoped lang="scss">
.box {
  position: relative;
  height: 100%;
  .title {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.1vw;
    text-align: center;
  }
  .content {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 51%;
    transform: translate(-50%, -50%);
  }
  .none {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1.1vw;
  }
}
</style>
