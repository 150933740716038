import Vuex from 'vuex'
import Vue from 'vue'
import requset, { socket } from './require'
Vue.use(Vuex)
import axios from 'axios'
import createPersistedState from "vuex-persistedstate"

export default new Vuex.Store({
  state: {
    code: '000000',
    socket: {
      isConnected: false,  // 连接状态
      reconnectError: false,  // 是否重试失败
    },
    userInfo: {
      rkId: null, // userId
      name: null, // 姓名
      gender: null, // 性别
      age: null, // 年龄
      ambulanceCode: null,  // 车code
      ambulanceName: null,  // 车牌号
      zyDateTime: null, //转院时间
    },
    vitalSignsList: {
      timeList: [], // 时间
      systolicList: [], // 高压
      diastolicList: [], // 低压
      heartRateList: [], // 心率
      temperatureList: [] // 温度
    },
    userState: 'over',
    weather: {
      cityid: null,
      date: null,
      week: null,
      update_time: null,
      city: null,
      cityEn: null,
      country: null,
      countryEn: null,
      wea: null,
      wea_img: null,
      tem: null,
      tem1: null,
      tem2: null,
      win: null,
      win_speed: null,
      win_meter: null,
      humidity: null,
      visibility: null,
      pressure: null,
      air: null,
      air_pm25: null,
      air_level: null,
      air_tips: null,
      alarm: {
        alarm_type: null,
        alarm_level: null,
        alarm_content: null
      }
    }
  },
  getters: {
    rkId (state) {
      return state.userInfo.rkId
    },
    peopleInfo (state) {
      if (state.userInfo.name != undefined) {
        if (state.userInfo.name.length === 2) {
          state.userInfo.name = state.userInfo.name.substring(0, 1) + '*'//substring(截取下标) str = qwertyuiop; sub = str.substring(2,5); sub = ert;
        } else if (state.userInfo.name.length === 3) {
          state.userInfo.name = state.userInfo.name.substring(0, 1) + '*' + state.userInfo.name.substring(2)
        } else {
          state.userInfo.name = state.userInfo.name.substring(0, 1) + '**' + state.userInfo.name.substring(3)
        }
      }
      return [state.userInfo.name, state.userInfo.gender, state.userInfo.age, state.userInfo.zyDateTime, state.userInfo.ambulanceCode, state.userInfo.ambulanceName]
    },
    peopleState (state) {
      state.vitalSignsList.temperatureList[state.vitalSignsList.temperatureList.length - 1] =
      parseFloat(Math.floor(state.vitalSignsList.temperatureList[state.vitalSignsList.temperatureList.length - 1] * 10) / 10)
      return [
        state.vitalSignsList.temperatureList[state.vitalSignsList.temperatureList.length - 1] || 0,
        (state.vitalSignsList.systolicList[state.vitalSignsList.systolicList.length - 1] || 0) + '/' + (state.vitalSignsList.diastolicList[state.vitalSignsList.diastolicList.length - 1] || 0),
        state.vitalSignsList.heartRateList[state.vitalSignsList.heartRateList.length - 1] || 0
      ]
      console.log('78945612',keepTwoDecimal(state.vitalSignsList.temperatureList[state.vitalSignsList.temperatureList.length - 1]));
    }
  },
  mutations: {
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget
      state.socket.isConnected = true
    },
    SOCKET_ONCLOSE (state, event)  {
      state.socket.isConnected = false
    },
    SOCKET_ONERROR(state, event) {
      console.error(state, event)
    },
    SOCKET_ONMESSAGE(state, message) {
      if (message.code !== '00000' || !message.data) {
        return
      }
      if (message.status === '0') {
        this.commit('setUserInfo', message.data)
        this.commit('setUserState', 'start')
        const msg = '患者开始转院'
        this.dispatch('videoActions',{msg})
        return
      }
      if (message.status === '1') {
        this.commit('setUserState', 'over')
        const msg = '患者转院完成'
        this.dispatch('videoActions',{msg})
        return
      }

      this.commit('pushVitalSignsList', message.data)
      this.commit('setUserState', 'roading')
    },
    SOCKET_RECONNECT(state, count) {
      console.info(state, count)
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },
    setUserInfo(state, user) {
      state.userInfo.rkId = user.rkId
      state.userInfo.name = user.name
      state.userInfo.gender = user.gender
      state.userInfo.age = user.age
      state.userInfo.ambulanceCode = user.ambulanceCode
      state.userInfo.ambulanceName = user.ambulanceName
      state.userInfo.zyDateTime = user.zyDateTime
    },
    setVitalSignsList(state, data) {
      state.vitalSignsList = data
      this.commit('setUserState', 'roading')
    },
    setUserState(state, data) {
      state.userState = data
    },
    pushVitalSignsList(state, data) {
      const { time, systolic, diastolic, heartRate, temperature } = data
      if(state.vitalSignsList.timeList.length >= 10 ) {
        state.vitalSignsList.timeList.splice(0, 1)
        state.vitalSignsList.systolicList.splice(0, 1)
        state.vitalSignsList.diastolicList.splice(0, 1)
        state.vitalSignsList.heartRateList.splice(0, 1)
        state.vitalSignsList.temperatureList.splice(0, 1)
      }
      state.vitalSignsList.timeList.push(time)
      state.vitalSignsList.systolicList.push(systolic)
      state.vitalSignsList.diastolicList.push(diastolic)
      state.vitalSignsList.heartRateList.push(heartRate)
      state.vitalSignsList.temperatureList.push(temperature)
    },
    setWeather(state, data) {
      state.weather = {
        ...data,
        date: Date.now()
      }
    }
  },
  actions: {
    getZyData({ commit, state }) {
      return requset.get(`getZyData/${state.code}`).then(res => {
        if (res.data.code === "00000" && res.data.data.zyDataList.length > 0) {
          let user = res.data.data.zyDataList[0]
          commit('setUserInfo', user)
        }
      })
    },
    getWeather({commit, state}) {
      axios.get('https://www.tianqiapi.com/api/', {
        params: {
          appid: '26148275',
          appsecret: '2id6H48Y',
          version: 'v6'
        }
      }).then(res => {
        if(res.data && !res.data.errcode) {
          commit('setWeather', res.data)
        }
      }).catch(err => {
      })
    },
    videoActions({ commit }, payload) {
      const speech = new SpeechSynthesisUtterance();
      speech.lang = 'zh-CN';
      speech.text = payload.msg;
      speech.pitch = 2;  //音调
      speech.rate = 0.6; // 语速
      speech.volume = 1;  //音量
      window.speechSynthesis.speak(speech)
    }
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      key: 'ReferralReport',
      reducer(val) {
        return {
          'weather': val.weather
        }
      }
    })
  ],
  keepTwoDecimal(context, num) {  
    var result = parseFloat(num);  
    if (isNaN(result)) {  
      alert('传递参数错误，请检查！');  
      return false;  
    }  
    result = Math.floor(num * 10) / 10;  
    return result;  
  }  
}) 