<template>
  <div class="box">
    <div class="title">胸 痛 患 者 趋 势 图</div>
    <div class="content">
      <div id="chartContainer" style="width: 50vw; height: 35vh"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts/core'
import request from '../config/require'
const colors = [
  '#5E6EC0',
  '#9BCB7E',
  '#F1CB6A',
  '#DE6E6A',
  '#86BEDB',
  '#56A076',
  '#EC8B5C',
  '#9462AF',
  '#F9E052',
]
import { mapState } from 'vuex'
export default {
  data() {
    return {
      options: {
        color: colors,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
        },
        grid: {
          left: '8%',
          right: '10%',
          containLabel: false,
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true },
          },
          show: false,
        },
        legend: {
          textStyle: {
            color: '#yellow',
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 2,
            // color: '#fff',
          },
          data: ['Evaporation', 'Precipitation', 'Temperature'],
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff',
              },
            },
            // prettier-ignore
            data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: 'Evaporation',
            position: 'right',
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[0],
              },
            },
            axisLabel: {
              formatter: '{value} ml',
            },
          },
          {
            type: 'value',
            name: 'Precipitation',
            position: 'right',
            alignTicks: true,
            offset: 60,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[1],
              },
            },
            axisLabel: {
              formatter: '{value} ml',
            },
          },
          {
            type: 'value',
            name: '温度',
            position: 'left',
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[1],
              },
            },
            axisLabel: {
              formatter: '{value} °C',
            },
          },
        ],
        series: [
          {
            name: 'Evaporation',
            type: 'bar',
            data: [
              2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4,
              3.3,
            ],
          },
          {
            name: 'Precipitation',
            type: 'bar',
            yAxisIndex: 1,
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0,
              2.3,
            ],
          },
          {
            name: 'Temperature',
            type: 'line',
            yAxisIndex: 2,
            data: [
              2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2,
            ],
          },
        ],
      },
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
  },

  methods: {
    getData() {
      const _this = this
      //
      //当前使用的 api (线上数据) || user(本地数据)

      //
      // console.log(
      //   '00000000000000000000000000000000000000000',
      //   this.$store.state.code
      // )
      request
        .get(`getStatisticsPatientTrend/${this.$store.state.code}`)
        .then(async (res) => {
          if (res && res?.data?.code === '00000') {
            let data = await res.data.data
            console.log('趋势', data)
            _this.options.xAxis[0].data = data.timeList
            // _this.options.xAxis[0].nameTextStyle.color = '#fff'
            // console.log(data)
            _this.options.legend.data = data.typeList
            _this.options.yAxis = data.typeList.map((item, index) => {
              return {
                type: 'value',
                name: '',
                position: 'right',
                alignTicks: true,
                axisLine: {
                  show: false,
                  lineStyle: {
                    color: colors[index],
                  },
                },
                axisLabel: {
                  formatter: '{value} 个',
                },
              }
            })

            _this.options.yAxis[_this.options.yAxis.length - 1] = [
              {
                type: 'value',
                name: '每月总数',
                position: 'left',
                alignTicks: true,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: '#5470C6',
                  },
                },
                axisLabel: {
                  formatter: '{value} 个',
                },
              },
            ]

            _this.options.series = data.dataList.map((item, index) => {
              return {
                name: item.name,
                yAxisIndex: 1,
                type: 'bar',
                data: item.data,
              }
            })
            _this.options.series.push({
              name: '每月总数',
              type: 'line',
              yAxisIndex: _this.options.series.length,
              data: data.totalList,
            })
            _this.initChart()
          }
        })
    },
    initChart() {
      var myChart = echarts.init(document.getElementById('chartContainer'))
      myChart.setOption(this.options)
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
  },
}
</script>

<style scoped lang="scss">
.box {
  position: relative;
  height: 100%;
  .title {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.1vw;
    text-align: center;
  }
  .content {
    width: 100%;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
  }
}
</style>
