<template>
  <div class="box">
    <div class="title">胸 痛 绿 色 通 道</div>
    <div class="content">
      <div class="list">
        <div class="item" v-for="(item, index) in list" :key="index">
          <dv-decoration-9 style="width: 4rem; height: 4rem"
            >{{ item.value }}人</dv-decoration-9
          >
          <div style="text-align: center; margin-top: 0.3vw; fontsize: 0.8vw">
            {{ item.namee }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import request from '../config/require'
export default {
  name: 'RingChart',
  components: '',
  data() {
    return {
      list: [
        {
          id: 1,
          value: 50,
          namee: '当日',
        },
        {
          id: 2,
          value: 60,
          namee: '本周',
        },
        {
          id: 3,
          value: 70,
          namee: '本月',
        },
        {
          id: 4,
          value: 2098,
          namee: '总数',
        },
      ],
      config: {
        data: [],
      },
    }
  },
  created() {},
  mounted() {
    this.getData()
    setInterval(() => {
      this.getData()
    }, 1 * 60 * 1000)
  },
  computed: {
  },
  methods: {
    // get data
    getData() {
      //
      //当前使用的 api (线上数据) || user(本地数据)
      //
      request
        .get(`getNumberOfPatientsStatistics/${this.$store.state.code}`)
        .then((res) => {
          console.log(res)
          if (res && res?.data?.code === '00000') {
            console.log(res.data)
            let {
              today_num: day = 0,
              month_num: month = 0,
              week_num: week = 0,
              all_num: num = 0,
            } = res.data.data
            let data = [day, week, month, num]
            this.list.forEach((item, index) => {
              item.value = data[index]
            })
          }
        })
    },
    //
  },
  watch: {},
}
</script>

<style scoped lang="scss">
.box {
  height: 100%;
  .title {
    width: 100%;
    height: 20%;
    padding-top: 1rem;
    font-size: 1.1vw;
    text-align: center;
    vertical-align: center;
  }
  .content {
    width: 100%;
    height: 80%;
    position: relative;

    .list {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: space-around;
      .item {
        display: flex;
        flex-direction: column;
        margin: 0 0.65vw;
        cursor: pointer;
      }
    }
  }
}
</style>
