import axios from "axios";
export let bodyClient = {
    text: [
        {
          x: 0.21,
          y: 0.27,
          text: '体温 ',
          number: 37.3,
          unit: "(℃)",
          color: '#cdfdfe'
        }, 
        {
          x: 0.11,
          y: 0.63,
          text: '血压 ',
          number: 120,
          unit: "",
          color: '#fcff7d'
        },
        {
          x: 0.66,
          y: 0.47,
          text: '心率 ',
          number: 100,
          unit: "(bpm)",
          color: '#faffff'
          
        }
      ],
      line : [
        {
          x1: 0.34,
          y1: 0.22,
          x2: 0.38,
          y2: 0.16,
          x3: 0.47,
          y3: 0.16,
          color: '#cdfdfe'
        },
        {
          x1: 0.2,
          y1: 0.59,
          x2: 0.25,
          y2: 0.47,
          x3: 0.33,
          y3: 0.47,
          color:'#fcff7d'
        },
        {
         x1: 0.76,//右下线 ，越小越往左
         y1: 0.43,//y轴 上小
         x2: 0.68,//中间 右大
         y2: 0.32,
         x3: 0.52,
         y3: 0.32,
         color: '#faffff'
        }
      ]
}

export const tenantId = "000000";
export const mapSecret = "fbTQXlsjWmypc1GsTewg19PeY9pCVDKe";
export const  getMapPath = async function () {
  // let url = `https://api.map.baidu.com/getscript?v=3.0&ak=fbTQXlsjWmypc1GsTewg19PeY9pCVDKe&services=&t=20230906113803`;
  // let url = `https://api.map.baidu.com/directionlite/v1/driving?origin=37.150831,109.688358&destination=36.625807,109.536519&ak=${mapSecret}`;
  let url = `https://api.map.baidu.com/directionlite/v1/driving?origin=34.569804,105.899713&destination=34.571421,105.88817&ak=${mapSecret}`;
  return await axios.get(url);
}
export const getTime = function() {
  let t = new Date();
  let h = t.getHours()
  let m = t.getMinutes()
 
  if (h < 10) {
    h = "0" + h;
  }
  if (m < 10) {
    m = "0" + m;
  }
  // console.log('111111111111111111111111',h + ":" + m);
  return h + ":" + m;
}