<template>
  <dv-full-screen-container>
    <!-- 加载 -->
    <!-- <dv-loading>Loading...</dv-loading> -->
    <!-- 顶部 -->
    <div class="header">
      <div class="weather">
        <Weizhi />
      </div>

      <div class="bigtit">
        <Titttle />
      </div>

      <div class="dattime">
        <Dtime />
      </div>
      <!-- <button @click="exitFullscreen">退出全屏</button> -->
      <!-- <button v-if="!isFullscreen" @click="toggleFullscreen">全屏</button> -->
      <!-- <img v-if="!isFullscreen" src="../assets/fangda.png" @click="toggleFullscreen" /> -->
    </div>
    <!-- 主体 -->
    <div class="container">
      <!-- 左侧主题 -->
      <div class="row">
        <div class="cel">
          <div class="topp">
            <dv-border-box-12>
              <Decoration9Vue />
            </dv-border-box-12>
          </div>
          <div class="cent">
            <dv-border-box-12>
              <Decoration99Vue />
            </dv-border-box-12>
          </div>
          <div class="bott">
            <dv-border-box-12>
              <RingChart></RingChart>
            </dv-border-box-12>
          </div>
        </div>
        <!-- 中间地图 -->
        <div class="cell">
          <div class="topt">
            <dv-border-box-12>
              <MappVue />
            </dv-border-box-12>
          </div>
          <div class="botb">
            <dv-border-box-12>
              <Qushitu />
            </dv-border-box-12>
          </div>
        </div>

        <!-- 右侧人体模型 -->
        <div class="cells">
          <dv-border-box-12>
            <PeopleVue style="width: 100%; height: 50vh" />
          </dv-border-box-12>
          <!-- <hr style="width: 100%;border: 1px dashed #2E6099;"> -->
          <dv-border-box-12>
            <HeatterVue />
          </dv-border-box-12>
        </div>
      </div>
    </div>
  </dv-full-screen-container>
</template>

<script>
import Decoration9Vue from './components/Greenchannel.vue'
import Decoration99Vue from './components/Comehospital.vue'
import Dtime from './components/Dtime.vue'
import HeatterVue from './components/Heatter.vue'
import MappVue from './components/Bmap.vue'
import PeopleVue from './components/People.vue'
import Qushitu from './components/Qushitu.vue'
import RingChart from './components/RingChart.vue'
import Titttle from './components/Titttle.vue'
import Weizhi from './components/Weizhi.vue'
import screenfull from 'screenfull'
import { mapState } from 'vuex'
export default {
  components: {
    RingChart,
    Dtime,
    Weizhi,
    Titttle,
    Qushitu,
    Decoration9Vue,
    Decoration99Vue,
    MappVue,
    PeopleVue,
    HeatterVue,
  },
  computed: {
  },
  data() {
    return {
    }
  },
  mounted() {
     this.$store.dispatch('getZyData')
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}
body {
  width: 100%;
  height: 100%;
  margin: 0;
  background: url(./assets/img/bg.jpg);
}
.header {
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  

  .weather {
    width: 30%;
    height: 100%;
    text-align: center;
    color: #fff;
    line-height: 30px;
  }
  .bigtit {
    width: 40%;
    height: 100%;
    text-align: center;
    color: #fff;
    // background-color: rgba(255,255,255, 0.5);
  }
  .dattime {
    width: 30%;
    height: 100%;
    // background-color: rgba(255,255,255, 0.2);
    color: #fff;
    text-align: center;
    line-height: 100px;
  }
}
.container {
  height: 90vh;
  .row {
    height: 100%;
    width: 100%;
    display: flex;
    color: #fff;
    .cel {
      flex: 1;
      .topp {
        height: 25%;
        width: 100%;
      }
      .cent {
        height: 25%;
        width: 100%;
      }
      .bott {
        height: 50%;
        width: 100%;
      }
    }
    .cell {
      flex: 2;
      .topt {
        height: 55%;
        width: 100%;
      }
      .botb {
        height: 45%;
        width: 100%;
      }
    }
    .cells {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
